import React from "react"
import { Router } from "@reach/router"

import NotFound from "../pages/404"

import Welcome from "../pages/community/welcome"
import Explore from "../pages/community/explore"
import Process from "../pages/community/process"
import ViewStage from "../pages/community/view-stage"
import StageOne from "../pages/community/stage-one"
import StageTwo from "../pages/community/stage-two"
import StageThree from "../pages/community/stage-three"
import StageFour from "../pages/community/stage-four"
import Profile from "../pages/community/profile"
import EditProfile from "../pages/community/edit-profile"
import Person from "../pages/community/person"
import Membership from "../pages/community/membership"
import Subscribe from "../pages/community/subscribe"
import Manage from "../pages/community/manage"
import CTermsAndConditions from "../pages/community/terms-and-conditions"
import DataPolicy from "../pages/community/data-policy"
import Faq from "../pages/community/faq"
import Content from "../templates/content"

import Assigned from "../pages/community/assigned"
import AssignedPerson from "../pages/community/assignedPerson"

export const NoAuth = user => (
  <Router>
    <Welcome path="/community/" />
    <Profile path="/community/profile" />
    <NotFound default />
  </Router>
)

export const Designer = user => (
  <Router>
    <Explore path="/community/" />
    <Process path="/community/process" />
    <ViewStage path="/community/view-stage/:id" user={user} />
    <StageOne path="/community/stage-one" user={user} />
    <StageTwo path="/community/stage-two" user={user} />
    <StageThree path="/community/stage-three" user={user} />
    <StageFour path="/community/stage-four" user={user} />
    <Profile path="/community/profile" />
    <EditProfile path="/community/edit-profile" />
    <Person path="/community/people/:name" />
    <Membership path="/community/membership" />
    <Subscribe path="/community/subscribe" />
    <Manage path="/community/membership/manage" />
    <CTermsAndConditions path="/community/terms-and-conditions" />
    <DataPolicy path="/community/data-policy" />
    <Faq path="/community/FAQ" />
    <Content path="/community/content/:id" />
    <NotFound default />
  </Router>
)

export const Expert = user => (
  <Router>
    <Assigned path="/community/" />
    <AssignedPerson path="/community/people/:name" />
    <ViewStage path="/community/view-stage/:id" user={user} />
    <NotFound path="/community/view-stage/undefined" />
    <Membership path="/community/membership" />
    <Subscribe path="/community/subscribe" />
    <Content path="/community/content/:id" />
    <Manage path="/community/membership/manage" />
    <CTermsAndConditions path="/community/terms-and-conditions" />
    <DataPolicy path="/community/data-policy" />
    <Faq path="/community/FAQ" />
    <NotFound default />
  </Router>
)
