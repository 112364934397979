export const isMobile = typeof window !== "undefined" && window.innerWidth < 459

export const uploadImage = async file => {
  await fetch(
    `https://${process.env.GATSBY_SANITY_PROJECT_ID}.api.sanity.io/v1/assets/images/${process.env.GATSBY_SANITY_DATASET}`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_SANITY_TOKEN}`,
      },
      body: file,
    }
  )
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error(error))
}
