import React from "react"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

//components
import Menu from "../components/Community/Menu"
import Loading from "../components/Loading"
import { Flex } from "../components/system"
import CommunityFooter from "../components/CommunityFooter"

//routes
import { Designer, Expert, NoAuth } from "../utils/routes"

const router = role => {
  switch (role) {
    case "Expert":
      return <Expert />
      break
    case "Designer":
      return <Designer />
      break
    default:
      return <NoAuth />
  }
}

const Community = () => {
  const { isLoading, isAuthenticated, error, user } = useAuth0()

  const role = user && user["https://ilana.uk/roles"][0]

  if (isLoading) {
    return (
      <Flex width="100vw" height="100vh">
        <Loading />
      </Flex>
    )
  }

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isAuthenticated) {
    return (
      <>
        <Menu role={role} />
        {router(role)}
        <CommunityFooter />
      </>
    )
  }

  return null
}

export default withAuthenticationRequired(Community)
