import React from "react"
import Blocks from "../components/Blocks"
import { Box, Title, Flex, Label, Text } from "../components/system"
import Video from "../components/Video"
import theme from "../components/theme"

const Content = ({ location }) => {
  const { title, file, _rawDescription, exclusive, type } = location.state.data
  const plan = location.state.plan

  console.log(file)

  if (plan === "basic" && exclusive) {
    return (
      <Flex flexDirection="column" py={[6]}>
        <Label fontSize={1} color={theme.colors.faded}>
          Content only available to
        </Label>
        <Title fontSize={[6, 7]} color={theme.colors.secondary}>
          Plus Members
        </Title>
        <Text maxWidth={600} px={3}>
          Email community@ilana.uk for support
        </Text>
      </Flex>
    )
  } else {
    return (
      <Box>
        <Title my={5}>{title}</Title>
        {type === "Video" && <Video url={file.asset.url} controls={true} />}
        {type === "PDF" && (
          <Flex mb={5}>
            <a download href={file.asset.url} target="_blank">
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="space-between"
                width={["90vw", "40vw", "20vw"]}
                height={["90vw", "40vw", "20vw"]}
                border={"2px solid black"}
                borderRadius={5}
                p={3}
              >
                <Flex flexDirection="column" alignItems="flex-start">
                  <Title mb={1} color={theme.colors.secondary}>
                    PDF
                  </Title>
                  <Text textAlign="left" color={"black"}>
                    {file.asset.originalFilename}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text textAlign="left" mb={0} color="black">
                    Download now
                  </Text>
                  <Flex
                    bg="black"
                    width={40}
                    height={40}
                    minWidth={40}
                    borderRadius={20}
                    style={{ transform: "rotate(90deg)" }}
                  >
                    <Text mb={0} color="white">
                      ->
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </a>
          </Flex>
        )}
        {_rawDescription && <Blocks blocks={_rawDescription} />}
      </Box>
    )
  }
}

export default Content
