import React, { useState } from "react"
import { Link } from "@reach/router"
import { useAuth0 } from "@auth0/auth0-react"
import { HamburgerMenuIcon, Cross1Icon } from "@modulz/radix-icons"
import { isMobile } from "../../utils"
import { Flex, Text, Image, CommunityLink } from "../system"
import { Logo } from "../Icons"
import { motion } from "framer-motion"
import Layout from "./Layout"

const variants = {
  open: { x: 0 },
  closed: { x: "100%" },
}

const Menu = () => {
  const { user, logout } = useAuth0()
  const [toggle, setToggle] = useState(false)
  const { avatar } = user && user["https://ilana.uk/metadata"]
  const role = user["https://ilana.uk/roles"][0]

  return (
    <Layout>
      <Flex justifyContent="space-between" p={[3, 4]}>
        <Link to="/">
          <Logo color="#5C4030" size={80} />
        </Link>
        {isMobile ? (
          <>
            <Flex
              bg="black"
              height={50}
              width={50}
              borderRadius={25}
              zIndex={3}
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? (
                <Cross1Icon color="white" />
              ) : (
                <HamburgerMenuIcon color="white" />
              )}
            </Flex>
            <Hidden
              transition={{ duration: 0.6, ease: [0.19, 1.0, 0.22, 1.0] }}
              initial="closed"
              animate={toggle ? "open" : "closed"}
              position="fixed"
              variants={variants}
              bg="secondary"
              color="white"
              width="100vw"
              height="100vh"
              top={0}
              left={0}
              zIndex={2}
              flexDirection="column"
            >
              {role === "Designer" ? (
                <>
                  <CommunityLink
                    to="/community/"
                    fontSize={[5, 50]}
                    onClick={() => setToggle(!toggle)}
                  >
                    Explore
                  </CommunityLink>
                  <CommunityLink
                    to="/community/process"
                    fontSize={[5, 50]}
                    onClick={() => setToggle(!toggle)}
                  >
                    Process
                  </CommunityLink>
                  <CommunityLink
                    to="/community/membership"
                    fontSize={[5, 50]}
                    onClick={() => setToggle(!toggle)}
                  >
                    Membership
                  </CommunityLink>
                  <CommunityLink
                    to="/community/profile"
                    fontSize={[5, 50]}
                    onClick={() => setToggle(!toggle)}
                  >
                    My Profile
                  </CommunityLink>
                </>
              ) : (
                <>
                  <CommunityLink
                    to="/community/"
                    fontSize={[5, 50]}
                    onClick={() => setToggle(!toggle)}
                  >
                    Dashboard
                  </CommunityLink>
                  <CommunityLink
                    to="/community/membership"
                    fontSize={[5, 50]}
                    onClick={() => setToggle(!toggle)}
                  >
                    Membership
                  </CommunityLink>
                  <CommunityLink
                    to="/"
                    fontSize={[5, 50]}
                    onClick={() => logout()}
                  >
                    Logout
                  </CommunityLink>
                </>
              )}
            </Hidden>
          </>
        ) : (
          <>
            <>
              {role === "Designer" ? (
                <Flex display={["none", "flex"]}>
                  <Link to="/community/">
                    <Text mr={5} mb={0}>
                      Explore
                    </Text>
                  </Link>
                  <Link to="/community/process/">
                    <Text mr={5} mb={0}>
                      Process
                    </Text>
                  </Link>
                  <Link to="/community/membership">
                    <Text mr={5} mb={0}>
                      Membership
                    </Text>
                  </Link>
                </Flex>
              ) : role === "Expert" ? (
                <Flex display={["none", "flex"]}>
                  <Link to="/community/">
                    <Text mr={3} mb={0}>
                      Dashboard
                    </Text>
                  </Link>
                  <Link to="/community/membership">
                    <Text mr={3} mb={0}>
                      Membership
                    </Text>
                  </Link>
                  <Link to="/" onClick={() => logout()}>
                    <Text mr={3} mb={0}>
                      Logout
                    </Text>
                  </Link>
                </Flex>
              ) : null}
            </>
            <Link
              to={role === "Designer" ? "/community/profile" : "/community/"}
            >
              {avatar ? (
                <Image
                  src={avatar}
                  alt=""
                  width={50}
                  maxWidth={50}
                  height={50}
                  borderRadius={25}
                  mb={0}
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <Image
                  src="https://cdn.sanity.io/images/ybn5jal1/production/97da26a15d397161ac6024591e9147be985ae339-1080x1080.png"
                  alt=""
                  width={50}
                  maxWidth={50}
                  height={50}
                  borderRadius={25}
                  mb={0}
                  style={{ objectFit: "cover" }}
                />
              )}
            </Link>
          </>
        )}
      </Flex>
    </Layout>
  )
}

export default Menu

const Hidden = motion.custom(Flex)
